import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Box, Checkbox, Collapse, IconButton, TableCell, TableRow} from '@mui/material'
import classNames from 'classnames'
import React, {useState} from 'react'

import {MobileRow} from '../MobileRow'
import {useTableStyles} from '../styles'
import {GridValueRowModel} from '../types'

import {ResponsiveTableRowProps} from './ResponsiveTableRow.types'
// eslint-disable-next-line complexity
export const ResponsiveTableRow = <R extends GridValueRowModel>({
  keyField,
  selectionEnabled,
  isRowSelectable,
  isMobile,
  collapsedContent,
  row,
  rowIndex,
  columns,
  rowHeight,
  columnsWidth,
  scrollBoundary,
  onRowClick,
  shouldDisplayScrollButtons,
  rowsSelected,
  setRowsSelected,
  columnOrder,
  filteredColumns,
  mobileRowDetails,
  rowDetailsSelected,
  setRowDetailsSelected,
  rowDetailsAvailable,
  rowDetailsClosed,
  expandedIndice,
  setExpandedIndice,
  allowSelectNotSelectedYet = true,
  rowOptions
}: ResponsiveTableRowProps<R>) => {
  const {classes} = useTableStyles()
  const [collapsed, setCollapsed] = useState(false)

  return (
    <>
      <TableRow
        component="div"
        data-test-id={`row-${rowIndex + 1}-${row[keyField]}`}
        onClick={() => {
          isRowSelectable && setRowDetailsSelected(row[keyField])
          onRowClick && onRowClick(row)
        }}
        className={classNames({
          [classes.rowSelectable]: rowDetailsAvailable,
          [classes.rowSelected]:
            rowsSelected.size > 0
              ? rowsSelected.has(row[keyField])
              : rowDetailsSelected && !rowDetailsClosed && rowDetailsSelected === row[keyField],
          [(rowOptions?.resolveClassName && rowOptions.resolveClassName(row)) ?? '']: true
        })}
        style={{verticalAlign: 'top'}}
      >
        {selectionEnabled && isRowSelectable && isRowSelectable(row) ? (
          <TableCell
            component="div"
            data-test-id={`table-select-${rowIndex + 1}`}
            className={classNames(classes.headCell, classes.selectableHeadCell)}
            align="center"
            width={20}
          >
            <Checkbox
              data-test-id={`header-checkbox-${rowIndex + 1}`}
              checked={rowsSelected.has(row[keyField])}
              size="small"
              color="secondary"
              onClick={(event) => {
                event.stopPropagation()
              }}
              onChange={() => {
                rowsSelected.has(row[keyField])
                  ? setRowsSelected(
                      new Set([...rowsSelected].filter((item) => item !== row[keyField]))
                    )
                  : setRowsSelected(new Set([...rowsSelected, row[keyField]]))
              }}
              style={{padding: '8px'}}
              disabled={!rowsSelected.has(row[keyField]) && !allowSelectNotSelectedYet}
            />
          </TableCell>
        ) : selectionEnabled ? (
          <TableCell
            component="div"
            data-test-id={`table-select-empty-${rowIndex}`}
            className={classNames(classes.headCell, classes.selectableHeadCell)}
            align="center"
            width={20}
          />
        ) : null}
        {isMobile && (
          <MobileRow
            keyField={keyField}
            columns={columns}
            rowIndex={rowIndex}
            row={row}
            expandedIndice={expandedIndice}
            setExpandedIndice={setExpandedIndice}
            mobileRowDetails={mobileRowDetails}
            onRowClick={onRowClick}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            collapsedContent={collapsedContent}
          />
        )}
        {!isMobile &&
          (columnOrder || columns) &&
          !!collapsedContent &&
          filteredColumns &&
          filteredColumns.length > 0 && (
            <TableCell
              className={classNames({
                [classes.rowSelectedPinned]:
                  columnOrder?.[0].pinDirection &&
                  rowDetailsSelected &&
                  !rowDetailsClosed &&
                  rowDetailsSelected === row[keyField],
                [classes.rowNotSelectedPinned]:
                  columnOrder?.[0].pinDirection && rowDetailsSelected !== row[keyField]
              })}
              style={{
                position: columnOrder?.[0].pinDirection ? 'sticky' : undefined,
                left: shouldDisplayScrollButtons && scrollBoundary !== 'start' ? 34 : 0,
                zIndex: 1,
                ...(rowHeight ? {height: rowHeight} : {})
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%'
                }}
              >
                <IconButton
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    visibility: collapsedContent?.(row) ? 'visible' : 'hidden'
                  }}
                >
                  <KeyboardArrowDownIcon
                    style={{
                      transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease-in-out'
                    }}
                  />
                </IconButton>
              </Box>
            </TableCell>
          )}
        {!isMobile &&
          (columnOrder || columns)
            .filter(
              (column) =>
                column.noColumnSelection ||
                filteredColumns.map((column) => column.field).includes(column.field)
            )
            // eslint-disable-next-line complexity
            .map((column) => {
              const calculateLeftPosition = () => {
                if (shouldDisplayScrollButtons) {
                  if (scrollBoundary !== 'start' && !collapsedContent) {
                    return 34
                  }

                  if (scrollBoundary !== 'start' && !!collapsedContent) {
                    return 96
                  }
                }
                return collapsedContent ? 62 : 0
              }

              const leftPosition = calculateLeftPosition()

              const lastLeftChild = columnOrder?.filter((val) => val.pinDirection === 'left').pop()
              const firstRightChild = columnOrder
                ?.filter((val) => val.pinDirection === 'right')
                .shift()

              return (
                <TableCell
                  key={`${column.field}-${rowIndex}`}
                  data-test-id={`cell-${column.field}-${rowIndex + 1}-${row[keyField]}`}
                  align={column.align || column.headerAlign}
                  className={classNames({
                    [classes.rowSelectedPinned]:
                      column.pinDirection &&
                      rowDetailsSelected &&
                      !rowDetailsClosed &&
                      rowDetailsSelected === row[keyField],
                    [classes.rowNotSelectedPinned]:
                      column.pinDirection && rowDetailsSelected !== row[keyField],
                    [classes.tableRowRightShadow]:
                      column.pinDirection === 'left' &&
                      lastLeftChild?.field === column.field &&
                      scrollBoundary !== 'start',
                    [classes.tableRowLeftShadow]:
                      column.pinDirection === 'right' &&
                      firstRightChild?.field === column.field &&
                      scrollBoundary !== 'end'
                  })}
                  style={{
                    ...(column.width
                      ? {width: column.width}
                      : {flexGrow: column.flex || 1, flexBasis: 0}),
                    padding: '16px 16px',
                    position: column.pinDirection ? 'sticky' : undefined,
                    borderRight:
                      lastLeftChild?.field === column.field
                        ? scrollBoundary === 'start'
                          ? '3px solid #EAEDF0'
                          : undefined
                        : undefined,

                    borderLeft:
                      firstRightChild?.field === column.field
                        ? scrollBoundary === 'end'
                          ? '3px solid #EAEDF0'
                          : undefined
                        : undefined,
                    right:
                      column.pinDirection === 'right'
                        ? columnsWidth?.find((width) => width.field === column.field)?.width
                          ? columnsWidth?.find((width) => width.field === column.field)?.width
                          : 0
                        : undefined,
                    left:
                      column.pinDirection === 'left'
                        ? columnsWidth?.find((width) => width.field === column.field)?.width
                          ? columnsWidth?.find((width) => width.field === column.field)?.width === 0
                            ? leftPosition
                            : (columnsWidth.find((width) => width.field === column.field)?.width ||
                                0) + leftPosition
                          : leftPosition
                        : undefined,
                    zIndex: 1,
                    ...(rowHeight ? {height: rowHeight} : {})
                  }}
                >
                  {column.renderCell ? column.renderCell(row) : row[column.field]}
                </TableCell>
              )
            })}
      </TableRow>
      {collapsedContent?.(row) && !isMobile && (
        <TableRow>
          <TableCell colSpan={columns.length} style={{paddingBottom: 0, paddingTop: 0}}>
            <Collapse in={collapsed} style={{display: 'block'}} unmountOnExit>
              {collapsedContent(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
